<template>
	<div class="news" v-title="'新闻动态——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box">
            <img class="bran" src="../image/about/news_banner.jpg" alt="" />
        </div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/about/news_banner.jpg"
				alt=""
			/>
		</div>
        <div class="content">
			<div class="nav-list">
                <AboutNav></AboutNav>
            </div>
			<div class="new-list f-y-s-c">
				<div
					v-for="(item, index) in newsList"
					:key="index"
					class="list-item"
                    
				>
                    <div class="img-box">
					    <img @click="navToDetail(item.articleId)" class="img" :src="item.imageUrl" alt="" />
                    </div>
					<div class="info f-y-b-s">
						<span @click="navToDetail(item.articleId)" class="text1">{{ item.title }}</span>
						<span class="text2">{{ item.description }}</span>
                        <div class="box1 f-x-s-c">
                            <!-- <span class="text3">{{ formartData(item) }}</span>  -->
                            <!-- <span class="text4">|</span>  -->
                            <span class="text3">{{item.publishTime && item.publishTime.slice(0,10) }}</span>
                        </div>
						
					</div>
				</div>
                <Pagination
                    :page-size="size"
                    :current-page="current"
                    :total="total"
                    @current-change="changeCurrent"
                ></Pagination>
			</div>
		</div>

		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
import { getNewsData } from '@/api/news.js'
export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
	},
	data() {
		return {
			newsList: [],
            size:10,
            current:1,
            total:0
		}
	},
    mounted(){
        this.getNewsList()
    },
	methods: {
        navToDetail(id){
            this.$router.push({
                path:'/about/news-detail',
                query:{
                    id
                }
            })
        },
        changeCurrent(data){
            this.current = data
            this.getNewsList()
        },
        prePage(){
            if(this.current!=1){
                this.current -=1
                this.getNewsList()
            }
            
        },
        nextPage(){
            if(this.current<((this.total/this.size).toFixed(0))){
                this.current +=1
                this.getNewsList()
            }
        },
        async getNewsList(){
            try{
                const params = {
                    size:this.size,
                    current:this.current,
                }
                const res = await getNewsData(params)
                if(res.code==0){
                    this.newsList = res.data.records
                    this.total = res.data.total
                    window.scrollTo({
                        top:0,
                        behavior: 'smooth' // 平滑滚动
                    })
                }
            }catch(err){
                console.log(err);
            }
        }
    },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .news {
        overflow-x: hidden;
		height: 100%;
        .box {
            display: none;
		}
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
		.content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
            flex-flow: column;
			.nav-list {
				width:100%;
			}
			.new-list {
				width: 100%;
                padding: 0 10px 20px 10px;
                box-sizing: border-box;
                margin-top:20px;

				.list-item {
                    cursor: pointer;
					width: 100%;
                    height: 300px;
					margin-bottom: 15px;
                    padding: 10px;
                    background: #FFFFFF;
                    // box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                    border-radius: 9px;
                    box-sizing: border-box;
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    .img-box{
                        width: 100%;
                        min-height: 150px;
                        overflow: hidden;
                        border-radius: 9px;
                        .img {
                            width: 100%;
                            height: 100%;
                            border-radius: 9px;
                            object-fit: cover;
                        }
                    }
					
					.info {
						width: 100%;
						box-sizing: border-box;
                        height: 100%;
                        margin-top: 10px;
						.text1 {
                            
                            font-weight: 400;
                            font-size: 18px;
                            color: #241934;
                            line-height: 24px;
                            word-break: break-all;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
						}
						.text2 {
                            flex: 1;
                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #241934;
                            line-height: 16px;
							margin-top: 8px;
                            word-break: break-all;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
						}
                        .box1{
                            margin-top: 10px;
                            .text3{
                                
                                font-weight: 400;
                                font-size: 10px;
                                color: #666666;
                                line-height: 10px;
                            }
                            .text4{
                                margin: 0 10px;
                            }
                        }
					}
				}
			}
		}
	}
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.news {
		height: 100%;
        .box-mob{
            display: none;
        }
		.box{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 60px 60px 180px 60px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.nav-list {
				width: 440px;
				height: 679px;
				margin-right: 40px;
			}
			.new-list {
				// width: 1062px;
                flex: 1;

                .list-item:hover{
                    box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                    .img{
                        transform: scale(1.2);
                    }
                }
				.list-item {
                    
					width: 100%;
                    height: 410px;
					margin-bottom: 50px;
                    padding: 30px;
                    background: #FFFFFF;
                    // box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                    border-radius: 18px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .img-box{
                        min-width: 460px;
                        height: 350px;
                        overflow: hidden;
                        cursor: pointer;
                        border-radius: 18px;
                        margin-right: 32px;
                    }
					.img {
                        transition: all .6s;
                        width: 100%;
                        height: 100%;
					}
					.info {
						width: 100%;
						padding: 20px 0;
						box-sizing: border-box;
                        height: 100%;
						.text1 {
                            cursor: pointer;
                            
                            font-weight: 400;
                            font-size: 36px;
                            color: #241934;
                            line-height: 48px;
                            word-break: break-all;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
						}
						.text2 {
                            flex: 1;
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #241934;
                            line-height: 36px;
							margin-top: 25px;
                            word-break: break-all;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
						}
                        .box1{
                            margin-top: 60px;
                            .text3{
                                
                                font-weight: 400;
                                font-size: 24px;
                                color: #666666;
                                line-height: 24px;
                            }
                            .text4{
                                margin: 0 20px;
                            }
                        }
					}
				}
			}
		}
	}
    
}
@media screen and (min-width: 1360px) {
	.news {
		height: 100%;
        .box-mob{
            display: none;
        }
		.box{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 60px 60px 180px 60px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.nav-list {
				width: 440px;
				height: 679px;
				margin-right: 40px;
			}
			.new-list {
				width: 1062px;
                .list-item:hover{
                    box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                    .img{
                        transform: scale(1.2);
                    }
                }
				.list-item {
                    
					width: 100%;
                    height: 410px;
					margin-bottom: 50px;
                    padding: 30px;
                    background: #FFFFFF;
                    // box-shadow: 0px 8px 12px 0px rgba(0,0,0,0.12);
                    border-radius: 18px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .img-box{
                        min-width: 460px;
                        height: 350px;
                        overflow: hidden;
                        cursor: pointer;
                        border-radius: 18px;
                        margin-right: 32px;
                    }
					.img {
                        transition: all .6s;
                        width: 100%;
                        height: 100%;
					}
					.info {
						width: 100%;
						padding: 20px 0;
						box-sizing: border-box;
                        height: 100%;
						.text1 {
                            cursor: pointer;
                            
                            font-weight: 400;
                            font-size: 36px;
                            color: #241934;
                            line-height: 48px;
                            word-break: break-all;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
						}
						.text2 {
                            flex: 1;
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #241934;
                            line-height: 36px;
							margin-top: 25px;
                            word-break: break-all;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
						}
                        .box1{
                            margin-top: 60px;
                            .text3{
                                
                                font-weight: 400;
                                font-size: 24px;
                                color: #666666;
                                line-height: 24px;
                            }
                            .text4{
                                margin: 0 20px;
                            }
                        }
					}
				}
			}
		}
	}
    
}
</style>
